/**This file contains a constant object that has properties that are necessary
 * for the build found in the `dev` configuration in `angular.json`.
 */

export const environment = {
	production: true,
	baseUrl: 'https://slpsp-service-reparenting-ml3wrvi3fq-uk.a.run.app/api/',
	openidUrl: 'https://corp.sts.ford.com/adfs/oauth2/authorize',
	openidClientId: 'urn:slpsp:clientid:web_slpsupprod:prod',
	openidResource: 'urn:slpsp:resource:web_slpsupprod:prod',
	openidRedirectUrl: 'https://slpsp.ford.com',
	currentPage: '',
	accessmanagement:
		'https://slpsp-service-accessmgmt-ml3wrvi3fq-uk.a.run.app',
};
