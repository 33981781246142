import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxFrfUtilitiesModule } from '@wame/ngx-frf-utilities';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { SidebarModule } from 'primeng/sidebar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProfileComponent } from './modules/profile/profile.component';
import { SLPSharedModule } from './slpshared.module';

@NgModule({
	declarations: [AppComponent,ProfileComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		NgxFrfUtilitiesModule,
		SLPSharedModule,
		SidebarModule,
	],
	providers: [MessageService, DialogService],
	bootstrap: [AppComponent],
})
export class AppModule {}
