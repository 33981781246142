import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenuListModel } from 'src/app/models/menuListModel';
import { MenuItemsListService } from 'src/app/services/menuItem.services';
import { FetchMenuListService } from 'src/app/services/menus.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
	selector: 'side-bar',
	templateUrl: './side-bar.component.html',
	styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
	@ViewChild('pmenu') pmenu: any;

	@Input() set menuOpened(open: boolean) {
		if (open) {
			this.collapse();
		}
	}

	loading: boolean = false;
	menusList: MenuListModel[] = [];

	constructor(
		public fetchMenuListService: FetchMenuListService,
		public notificationService: NotificationService,
		public menuItemListService: MenuItemsListService
	) {}

	ngAfterViewInit() {
		this.collapse();
	}

	collapse() {
		this.pmenu?.collapseAll();
	}

	ngOnInit(): void {
		this.fetchMenuListItem();
	}

	/**
	 * Fetch the menu list item from the server
	 * and set in the server menuListFromServer
	 */
	async fetchMenuListItem() {
		this.loading = true;
		this.menusList = [];

		let response: any = await this.fetchMenuListService
			.fetchMenuList()
			.toPromise()
			.catch((err) => {
				this.loading = false;
				this.notificationService.somethingWentWrong();
			});
		if (!response?.length) {
			this.loading = false;
			return;
		}
		response.forEach((element) => {
			this.menusList.push(new MenuListModel().deserialize(element));
		});
		this.menuItemListService.setMenuListFromServer = this.menusList;
		this.loading = false;
	}
}
