import { Injectable } from '@angular/core';
import { AccessTokenInterface } from '../models/accessTokenInterface';
import { User } from '../models/user';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private _accessToken: string;
	private _accessTokenKey: string = 'access_token';
	private _user: User;
	private _accessScreensList: string[] = [];
	private _accessScreenKey: string = 'access_screens';
	private _noAccessKey: string = 'no_access';

	/**
	 * It wil store the access token in private key _accessToken
	 * aswell as decode the base64 access token and construct the deserialized
	 * User data.
	 */
	set accessToken(token: string) {
		if (!token) return;
		this._accessToken = token;
		sessionStorage.setItem(this._accessTokenKey, this._accessToken);
		let decodedBase64: string = atob(this._accessToken.split('.')[1]);
		let decodeJson: any = JSON.parse(decodedBase64);
		this._user = new User().deserialize(decodeJson);
	}

	/**
	 * Fetch the access token from the session storage
	 */
	get accessToken(): string {
		this.accessToken = sessionStorage.getItem(this._accessTokenKey);
		return this._accessToken;
	}

	/**
	 * Set the screen access list in the session storage.
	 */
	set screenAccessList(data: string[]) {
		if (!data) {
			this.clearSessionScreens();
			return;
		}
		this._accessScreensList = data;
		sessionStorage.setItem(this._accessScreenKey, JSON.stringify(data));
	}

	/**
	 * Parse the session storage data for the key access screen
	 */
	get screenAccessList(): string[] {
		let sessionData: string = sessionStorage.getItem(this._accessScreenKey);
		if (sessionData) {
			this.screenAccessList = JSON.parse(sessionData);
		} else {
			this.screenAccessList = null;
		}
		return this._accessScreensList;
	}

	/**
	 * If the screen Access list have admininstor then the user is an admin user
	 */
	get isAdminUser(): boolean {
		if (!this.screenAccessList || !this.screenAccessList?.length)
			return false;
		if (
			this.screenAccessList.some((data: string) => data.includes('Admin'))
		) {
			return true;
		}
		return false;
	}

	/**
	 * @param permitLable Array of permitted screens string
	 * @returns boolean
	 * if any one element in permitLable is found in screenAccessList
	 * then return true else false
	 * To allow blindly permit label must contain work SLPSP
	 */
	hasScreenPermission(permitLable: string[]): boolean {
		if (permitLable[0]?.indexOf('SLPSP:Allow') != -1) return true;
		let screenList: string[] = this.screenAccessList;
		for (let i = 0; i < permitLable.length; i++) {
			if (screenList.includes(permitLable[i])) {
				return true;
			}
		}
		return false;
	}

	/**
	 * @returns boolean
	 * check for the data in session storage
	 */
	get hasScreenData(): boolean {
		let sessionData: string = sessionStorage.getItem(this._accessScreenKey);
		if (!sessionData) return false;
		return true;
	}

	/**
	 * @returns boolean
	 * If the user has no screen permissions
	 * returns true else false
	 */
	get hasNoScreenAccess(): boolean {
		return this.screenAccessList.length == 0;
	}

	/** If the user have no access set the data in session storage as 0 else 1 */
	set noAccess(data: boolean) {
		sessionStorage.setItem(this._noAccessKey, data ? '1' : '0');
	}

	/** @return boolean ture if the user have value is not equal to 0*/
	get noAccess(): boolean {
		let access: string = sessionStorage.getItem(this._noAccessKey);
		if (access == 'undefined' || access == '' || access == 'null') {
			return false;
		}
		return +access != 0;
	}

	/** CLears the access screens list session storage */
	clearSessionScreens() {
		sessionStorage.removeItem(this._accessScreenKey);
		sessionStorage.removeItem(this._noAccessKey);
	}

	/**
	 * @returns boolean
	 * if access token exisits, return true
	 * else false
	 */
	get isAuthenticated(): boolean {
		return this.accessToken ? true : false;
	}

	get user(): User {
		return this._user;
	}

	get userName() {
		return this._user?.cdsid;
	}

	/**
	 * Decode the given string and convert the data to the
	 * required interface AccessTokenInterface
	 */
	decodeToken(fullToken: string): AccessTokenInterface {
		let token: string[] = fullToken.split('&');
		let map: any = {};
		token.forEach((value: string) => {
			let key = value.split('=')[0];
			let keyValue = value.split('=')[1];
			map[key] = keyValue;
		});
		return { accessToken: map['access_token'], expiry: map['expiry'] };
	}

	/** Clear the access token from the session */
	logOut(): void {
		this.clearSessionScreens();
		sessionStorage.removeItem(this._accessTokenKey);
	}
}
