import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/authenticationService';

/** Angular component that displays header for the application
 */
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	constructor(private authService: AuthService, private router: Router) {}
	@Output()
	openSlide: EventEmitter<void> = new EventEmitter();
	userName: string;

	ngOnInit(): void {
		this.userName = this.authService.userName;
	}

	/**
	 * Function to logout
	 */
	async logOut() {
		await this.authService.logOut();
		await this.router.navigate(['./home']);

		location.reload();
		return;
	}

	/**
	 * Function to got profile page
	 */
	goToProfile() {
		this.router.navigate(['/profile']);
	}
}
