import { AuthService } from './../services/authenticationService';
import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { AccessTokenInterface } from '../models/accessTokenInterface';
import { ScreenAccessService } from '../services/screenAccessService';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate {
	routerSubscriptions: Subscription;
	constructor(
		public router: Router,
		public authService: AuthService,
		public activatedRoute: ActivatedRoute,
		public screenAccessSerivce: ScreenAccessService
	) {}

	async canActivate() {
		if (!this.authService.isAuthenticated) {
			let fragment = window.location.hash;
			fragment = fragment?.substring(1);
			const hasAccessToken = await this.checkForTokenAuthentication(
				fragment
			);
			if (!hasAccessToken) {
				return false;
			}
		}
		return true;
	}

	/**
	 * Check for the access token in url and set the access token in authService
	 * If Url not available in token check for token already present in session using authService
	 * With the token it feches that user details
	 */
	async checkForTokenAuthentication(fragment) {
		if (fragment?.includes('access_token')) {
			let decodedToken: AccessTokenInterface =
				await this.authService.decodeToken(fragment);
			this.authService.accessToken = decodedToken.accessToken;
			this.fetchScreenAccessList();
			return true;
		}
		if (!this.authService.accessToken) {
			const currentUrl = window.location.href;
			this.authService.logOut();
			document.location.href =
				environment.openidUrl +
				'/adfs/oauth2/authorize/wia?response_type=token+id_token&client_id=' +
				environment.openidClientId +
				'&resource=' +
				environment.openidResource +
				'&' +
				'redirect_uri=' +
				currentUrl;
			return false;
		} else {
			this.fetchScreenAccessList();
			return true;
		}
	}

	/** Fetch the screen access list from APS Server */
	async fetchScreenAccessList() {
		this.screenAccessSerivce.endpointSufix = this.authService.user?.CDSID;
		let response: any = await this.screenAccessSerivce
			.fetchData()
			.toPromise()
			.catch((err: any) => {
				console.log("Error in Fetching Screen Access")
			});
		if (!response) {
			this.authService.screenAccessList = null;
			return;
		} else {
			let permittedScreens: any[] = response['Permitted'];
			if (!permittedScreens || permittedScreens?.length === 0) {
				this.authService.screenAccessList = null;
				this.authService.noAccess = true;
				return;
			}
			this.authService.noAccess = false;
			this.authService.screenAccessList = permittedScreens[0].read
				? permittedScreens[0].read
				: [];
			return;
		}
	}
}
