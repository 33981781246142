import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from '@wame/ngx-frf-utilities';
import { AuthGuard } from './guards/auth-guards';
import { ProfileComponent } from './modules/profile/profile.component';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		data: { mainMenu: true },

		canActivate: [AuthGuard],
	},
	{
		path: 'home',
		component: HomeComponent,
		data: { mainMenu: true },
		canActivate: [AuthGuard],
	},
	{
		path: 'admin',
		loadChildren: () =>
			import('./modules/admin/admin.module').then((m) => m.AdminModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'reparenting',
		loadChildren: () =>
			import('./modules/reparenting/reparenting.module').then(
				(m) => m.ReparentingModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: 'supply-code-change',
		loadChildren: () =>
			import(
				'./modules/supply-code-change/supply-code-change.module'
			).then((m) => m.SupplyCodeChangeModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'site-role-change',
		loadChildren: () =>
			import(
				'./modules/site-role-change/site-role-change.module'
			).then((m) => m.SiteRoleChangeModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'payment-guideline-change',
		loadChildren: () =>
			import(
				'./modules/payment-guideline-change/payment-guideline-change.module'
			).then((m) => m.PaymentGuidelineChangeModule),
		canActivate: [AuthGuard],
	},
	{
		path: 'profile',
		component: ProfileComponent,
		data: {
			breadcrumb: 'profile',
			mainMenu: true,
			permitLabel: ['SLPSP:Profile'],
		},
		canActivate: [AuthGuard],
	},
	{
		path: '404',
		component: NotFoundComponent,
		canActivate: [AuthGuard],
		data: { breadcrumb: 'Page Not Found' },
	},
	{
		path: '**',
		redirectTo: '/404',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
